<div *ngIf="price" [ngClass]="{ 'is-loading': isLoadingPlan }" class="summary">
  <div class="total-price">
    <span class="label">{{ 'payments.plan.price.title' | translate }}</span>
    <div class="price">
      {{ price.amount / 100 | number: '1.2-2' }}<span class="currency">{{ price.currency }}</span>
    </div>
    <span class="info"
      ><strong>+{{ 'payments.plan.price.tax' | translate }}</strong> {{ 'payments.plan.price.tax_info' | translate }}</span
    >
  </div>

  <div class="prices">
    <ul>
      <li>
        {{ 'payments.plan.price.monthly_subscription' | translate }}: {{ price.amount / 100 | number: '1.2-2' }}
        <span class="currency">{{ price.currency }}</span>
      </li>
      <li [ngClass]="{ active: price.setup! > 0 }">
        {{ 'payments.plan.price.setup_fees' | translate }}: {{ (price.setup || 0) / 100 | number: '1.2-2' }}
        <span class="currency">{{ price.currency }}</span>
      </li>
      <li *ngIf="isTrial">{{ 'payments.plan.price.free_version' | translate }}</li>
    </ul>
  </div>

  <button class="tw-mx-auto md:tw-mr-0" (click)="submit()" [ngClass]="{ trial: isTrial }" color="primary" mat-raised-button>
    {{ (isTrial ? 'payments.plan.price.trial_btn_txt' : 'payments.plan.price.buy_btn_txt') | translate }}
  </button>
  <div *ngIf="isLoadingPlan" class="spinner-wrapper">
    <mat-spinner diameter="40"></mat-spinner>
  </div>
</div>
